import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import rootReducer from './reducers';
import { StyleProvider } from '@ant-design/cssinjs';

const preloadedState = window.__PRELOADED_STATE__ || {};
delete window.__PRELOADED_STATE__;

const store = createStore(rootReducer, preloadedState, applyMiddleware(thunk));

const render = () => {
    ReactDOM.hydrate(
        <StyleProvider>
            <Provider store={store}>
                <App routerType="client" />
            </Provider>
        </StyleProvider>,
        document.getElementById('root')
    );
};

render();

if (module.hot) {
    module.hot.accept('./App', () => {
        render(); // อัปเดต UI เมื่อ App เปลี่ยน
    });
}