/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card, Spin } from "antd";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDimensions } from "../../common/mamager/ScreenManager";

import "./css/index.css";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import { Notification } from "../../common/components/notification";
import {
  getIssueFetch,
  insertSaleInfoCalculateFetch,
} from "./API/evaluateIntegrityApi";
import StepSaleMobile from "../../common/components/step";

export default function EvaluateIntegrity() {
  const [form] = Form.useForm();
  const { width } = useDimensions();

  const navigate = useNavigate();

  const userAgent = (typeof window !== 'undefined' ? navigator.userAgent : 'Unknown')
  const isMobileDevice = /iPhone|Android|iPad|Tablet/.test(userAgent)
  // console.log("isMobileDevice : ", isMobileDevice)

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const brand = params.get("brand");
  const model = params.get("model");
  const capacity = params.get("capacity");

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [issueInfo, setIssueInfo] = useState([]);

  const collectCriteria = async () => {
    let criteriaSelect = [];
    let countSelected = 0;
    let issueInfoIsItemNotEmpty = 0;
    for (let i = 0; i < issueInfo.length; i++) {
      if (issueInfo[i].item.length > 0) {
        issueInfoIsItemNotEmpty++;
      }

      let findCriteriaSelect = issueInfo[i].item?.filter(
        (fill) => fill.isSelect === true
      );
      criteriaSelect = criteriaSelect.concat(findCriteriaSelect);
      if (findCriteriaSelect.length > 0) {
        countSelected++;
      }
    }

    let issueOptions = [];
    for (let i = 0; i < criteriaSelect.length; i++) {
      issueOptions.push({
        issueOptionsId: criteriaSelect[i].id,
      });
    }

    let obj = {
      mobileCapacityId: capacity,
      issueOptions,
    };

    if (countSelected === issueInfoIsItemNotEmpty) {
      const result = await insertSaleInfoCalculateFetch(null, obj, null);
      console.log("insertSaleInfoCalculateFetch : ", result)
      if (result?.status === 200) {
        localStorage.setItem("saleInfo", JSON.stringify(result?.result));
        localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
        localStorage.setItem("issueInfo", JSON.stringify(criteriaSelect));
        navigate(`/phone-summary-result`);
      } else {
        Notification(
          "error",
          "เเจ้งเตือน",
          "พบปัญหาในการส่งประเมินราคา กรุณากรอกข้อมูลให้ครบ"
        );
      }
    } else {
      Notification(
        "error",
        "เเจ้งเตือน",
        "ไม่สามารถประเมินราคาได้ กรุณากรอกข้อมูลให้ครบ"
      );
    }
  };

  const displaySelectedEvaluation = (item) => {
    return (
      <>
        {item
          .filter((fill) => fill.isSelect === true)
          ?.slice(0, 3)
          ?.map((val, index) => {
            return (
              <label style={{ paddingRight: 12 }}>
                {/* {index + 1}) {val.title} */}
                {val.title}
              </label>
            );
          })}
        {item.filter((fill) => fill.isSelect === true).length > 3 && (
          <label style={{ paddingRight: 12 }}>...</label>
        )}
      </>
    );
  };

  const getIssue = async () => {
    let param = {
      mobileModelId: Number(model),
      mobileBrandId: Number(brand),
    };
    const result = await getIssueFetch(param, null, null);
    console.log("Issue", result);
    // console.log("getIssueFetch : ", result)
    if (result?.status === 200) {
      let tmpIssue = [];
      result?.result?.Issue?.map((val) => {
        let tmpItem = [];
        val.IssueOptions.map((item) => {
          tmpItem.push({
            title: item.issueOptionsTitle,
            id: item.issueOptionsId,
            isSelect: false,
          });
        });

        tmpIssue.push({
          id: val.issueId,
          title: val.issueTitle,
          isExpand: val.isExpand,
          isSingle: val.isSingle,
          isOpen: true,
          item: tmpItem,
        });
      });
      setIssueInfo(tmpIssue);
      setDeviceInfo({
        mobileModelTitle:
          result?.result?.MobileBrand?.MobileModel?.mobileModelTitle,
        mobileModelImgUrl:
          result?.result?.MobileBrand?.MobileModel?.googleImage,
        mobileModelId: Number(model),

        mobileBrandName: result?.result?.MobileBrand?.mobileBrandName,
        mobileBrandId: Number(brand),

        mobileCapacityId: Number(capacity),
        mobileCapacityName: result?.result?.MobileBrand?.MobileModel?.MobileCapacity?.filter(val => val.id === Number(capacity))[0]?.capacity
      });
    }
  };

  const getBaseApi = async () => {
    await getIssue();
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  console.log("deviceInfo --- : ", deviceInfo);
  console.log("issueInfo ---- : ", issueInfo);

  return (
    <>
      <Header />

      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            paddingTop:
              width > 1200 ? 150 : ((width > 760 && width < 1200) ? 150 : 94),
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <StepSaleMobile stepId={1} />
        </div>

        {deviceInfo && issueInfo?.length > 0 ?
          <div
            style={{
              display: width > 1050 ? "flex" : "grid",
              alignItems: "flex-start",
              paddingTop: width > 1200 ? 48 : 24,
              paddingBottom: 82,
              paddingLeft: width > 1200 ? 0 : 12,
              paddingRight: width > 1200 ? 0 : 12,
              maxWidth: 1000,
            }}
          >
            <div
              style={{
                width: width > 1050 ? 400 : "100%",
                paddingRight: width > 1050 ? 24 : 0,
                paddingBottom: width > 1050 ? 0 : 24,
              }}
            >
              {!isMobileDevice ?
                <Card hoverable>
                  <div style={{ display: "grid" }}>
                    <div style={{ paddingBottom: 12 }}>
                      <label className="ks-item-title">รายการรับซื้อ</label>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={deviceInfo?.mobileModelImgUrl ?? ""}
                        style={{
                          width: "100%",
                          height: width > 1050 ? 320 : "auto",
                          objectFit: "cover",
                        }}
                        alt="รายการรับซื้อ"
                      />
                    </div>

                    <div style={{ textAlign: "center", paddingTop: 12 }}>
                      <label style={{ fontSize: 20 }}>
                        {deviceInfo?.mobileModelTitle ?? "-"} {deviceInfo?.mobileCapacityName ?? "-"}
                      </label>
                    </div>
                  </div>
                </Card>
                :
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "center" }}>
                  {/* {console.log("deviceInfo : ", deviceInfo)} */}
                  <div style={{ width: 3, height: 28, backgroundColor: "rgb(255, 187, 85)", padding: 2 }}></div>
                  <label style={{ fontSize: 20, paddingLeft: 8 }} >ระบุการขาย {deviceInfo?.mobileModelTitle ?? "-"} {deviceInfo?.mobileCapacityName ?? "-"}</label>
                </div>
              }
            </div>

            <div style={{ display: "grid" }}>
              {issueInfo?.map((val, index1) => {
                // console.log(val);
                return (
                  <>
                    {val?.item?.length > 0 ? (
                      <div
                        key={index1}
                        style={{
                          width: width > 1050 ? 600 : "100%",
                          paddingBottom: 24,
                        }}
                      >
                        <div
                          style={{
                            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                            borderRadius: 8,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#FFBB55",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: 10,
                              borderTopRightRadius: 8,
                              borderTopLeftRadius: 8,
                              borderBottomLeftRadius: val.isOpen ? 0 : 8,
                              borderBottomRightRadius: val.isOpen ? 0 : 8,
                            }}
                          >
                            <div>
                              <label>{val.title}</label>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let tmp = issueInfo.map((find) =>
                                  find.id === val.id
                                    ? {
                                      id: find.id,
                                      title: find.title,
                                      isOpen: !find.isOpen,
                                      isSingle: find.isSingle,
                                      isExpand: val.isExpand,
                                      item: find.item,
                                    }
                                    : find
                                );
                                setIssueInfo(tmp);
                              }}
                            >
                              {val.isSingle ? (
                                displaySelectedEvaluation(val.item)
                              ) : val?.item?.filter(
                                (fill) => fill.isSelect === true
                              )?.length > 0 ? (
                                <label style={{ paddingRight: 4 }}>
                                  เลือกจำนวน{" "}
                                  {
                                    val?.item?.filter(
                                      (fill) => fill.isSelect === true
                                    )?.length
                                  }{" "}
                                  รายการ
                                </label>
                              ) : (
                                []
                              )}
                              <Icon
                                icon={
                                  val.isOpen
                                    ? "icon-park-outline:down"
                                    : "icon-park-outline:up"
                                }
                                style={{ width: 20, height: 20 }}
                              />
                            </div>
                          </div>

                          {val.isOpen ? (
                            <div
                              style={{
                                padding: 12,
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8,
                                display: "flex",
                                gap: "12px",
                                flexWrap: "wrap",
                              }}
                            >
                              {val.item.map((item, index2) => {
                                return (
                                  <div
                                    key={index2}
                                    className={
                                      item.isSelect
                                        ? "btn-sub-criteria-price-cut-active"
                                        : "btn-sub-criteria-price-cut"
                                    }
                                    onClick={() => {
                                      let findItem = issueInfo.filter(
                                        (fill) => fill.id === val.id
                                      )[0]?.item;

                                      let tmpItem = [];
                                      if (val.isSingle === true) {
                                        tmpItem = findItem.map((find) =>
                                          find.id === item.id
                                            ? {
                                              id: find.id,
                                              title: find.title,
                                              isSelect: !find.isSelect,
                                            }
                                            : {
                                              id: find.id,
                                              title: find.title,
                                              isSelect: false,
                                            }
                                        );
                                      } else {
                                        tmpItem = findItem.map((find) =>
                                          find.id === item.id
                                            ? {
                                              id: find.id,
                                              title: find.title,
                                              isSelect: !find.isSelect,
                                            }
                                            : find
                                        );
                                      }

                                      let tmp = issueInfo.map((find) =>
                                        find.id === val.id
                                          ? {
                                            id: find.id,
                                            title: find.title,
                                            isOpen:
                                              val.isSingle || val.isExpand
                                                ? !val.isOpen
                                                : true,
                                            // isOpen: find.isOpen,
                                            isExpand: val.isExpand,
                                            isSingle: find.isSingle,
                                            item: tmpItem,
                                          }
                                          : find
                                      );
                                      setIssueInfo(tmp);
                                    }}
                                  >
                                    <label style={{ cursor: "pointer" }}>
                                      {item.title}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            []
                          )}
                        </div>
                      </div>
                    ) : (
                      []
                    )}
                  </>
                );
              })}

              <div>
                <Button
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => {
                    collectCriteria();
                    window.scroll(0, 0);
                  }}
                >
                  <label style={{ cursor: "pointer" }}>ประเมินราคา</label>
                </Button>
              </div>
            </div>
          </div>
          :
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            width: '100%',
          }}>
            <Spin tip="Loading..." size="large" />
          </div>
        }

      </div>

      <Footer />
    </>
  );
}
