// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.btn-sub-criteria-price-cut {
    background-color: #EEEEEE;
    padding: 8px;
    text-align: center;
    width: 159px;
    cursor: pointer;
    border-radius: 8px;
}

.btn-sub-criteria-price-cut-active {
    background-color: #ffdaa2;
    padding: 8px;
    text-align: center;
    width: 159px;
    cursor: pointer;
    border-radius: 8px;
}

.btn-sub-criteria-price-cut:hover {
    background-color: #d9d9d9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 786px) {
    .btn-sub-criteria-price-cut {
        width: 100%;
    }

    .btn-sub-criteria-price-cut-active {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/client/modules/evaluateIntegrity/css/index.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,2CAA2C;AAC/C;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":["\n\n.btn-sub-criteria-price-cut {\n    background-color: #EEEEEE;\n    padding: 8px;\n    text-align: center;\n    width: 159px;\n    cursor: pointer;\n    border-radius: 8px;\n}\n\n.btn-sub-criteria-price-cut-active {\n    background-color: #ffdaa2;\n    padding: 8px;\n    text-align: center;\n    width: 159px;\n    cursor: pointer;\n    border-radius: 8px;\n}\n\n.btn-sub-criteria-price-cut:hover {\n    background-color: #d9d9d9;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);\n}\n\n@media (max-width: 786px) {\n    .btn-sub-criteria-price-cut {\n        width: 100%;\n    }\n\n    .btn-sub-criteria-price-cut-active {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
