/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Row, Col, Button, Form, Select, Spin } from "antd"
import { Icon } from "@iconify/react"
import { useDimensions } from "../../../../common/mamager/ScreenManager"

import "./css/index.css"
import { useNavigate } from "react-router"
import CorousBanner from "./CorousBanner"
import {
  getBannerFetch,
  getMobileBrandFetch,
  getMobileCapacityFetch,
  getMobileModelFetch
} from "../../API/estimatePriceApi"

const { Option } = Select

export default function Main() {
  const [form] = Form.useForm()
  const { width } = useDimensions()

  const navigate = useNavigate()

  const userAgent = (typeof window !== 'undefined' ? navigator.userAgent : 'Unknown')
  const isMobileDevice = /iPhone|Android|iPad|Tablet/.test(userAgent)
  // console.log("isMobileDevice : ", isMobileDevice)

  const [mobileBrand, setMobileBrand] = useState([])
  const [mobileModel, setMobileModel] = useState([])
  const [mobileCapacity, setMobileCapacity] = useState([])
  const [banner, setBanner] = useState([])

  const [isModalOpenMobileBrand, setModalOpenMobileBrand] = useState(false)
  const [isModalOpenMobileModel, setModalOpenMobileModel] = useState(false)
  const [isModalOpenMobileCapacity, setModalOpenMobileCapacity] = useState(false)
  const [selectedMobileBrand, setSelectedMobileBrand] = useState(null)
  const [selectedMobileModel, setSelectedMobileModel] = useState(null)
  const [selectedMobileCapacity, setSelectedMobileCapacity] = useState(null)

  const onFinish = async (values) => {

    let brand, model, capacity

    if (!isMobileDevice) {
      brand = values.brand ? values.brand : null
      model = values.model ? values.model : null
      capacity = values.capacity ? values.capacity : null
    } else {
      brand = selectedMobileBrand ? selectedMobileBrand.id : null
      model = selectedMobileModel ? selectedMobileModel.id : null
      capacity = selectedMobileCapacity ? selectedMobileCapacity.id : null
    }

    navigate(
      "/phone-evaluate-integrity?brand=" +
      brand +
      "&model=" +
      model +
      "&capacity=" +
      capacity
    )

    // set default
    setFormDefault();
  };

  const setFormDefault = () => {
    form.resetFields()
  }

  const getMobileBrand = async () => {
    const result = await getMobileBrandFetch(null, null, null)
    // console.log("getMobileBrandFetch : ", result?.result)

    if (result?.status === 200) {
      setMobileBrand(result?.result)
    }
  }

  const getMobileModel = async (id) => {
    let param = {
      mobileBrandId: id,
    }
    const result = await getMobileModelFetch(param, null, null)
    // console.log("getMobileModelFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileModel(result?.result)
    }
  }

  const getMobileCapacity = async (id) => {
    let param = {
      mobileModelId: id,
    }
    const result = await getMobileCapacityFetch(param, null, null)
    // console.log("getMobileCapacityFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileCapacity(result?.result)
    }
  }

  const getBanner = async (id) => {
    let param = {
      mobileModelId: id,
    }
    const result = await getBannerFetch(param, null, null)
    if (result?.status === 200) {
      setBanner(result?.result)
    }
  }

  const getBaseApi = async () => {
    await getMobileBrand()
    await getBanner()
  }

  const openModalMobileBrand = (e) => {
    e.preventDefault()
    setModalOpenMobileBrand(true)
  }

  const openModalMobileModel = (e) => {
    e.preventDefault()
    setModalOpenMobileModel(true)
  }

  const openModalMobileCapacity = (e) => {
    e.preventDefault()
    setModalOpenMobileCapacity(true)
  }

  const closeModalMobileBrand = () => {
    setModalOpenMobileBrand(false)
  }

  const closeModalMobileModel = () => {
    setModalOpenMobileModel(false)
  }

  const closeModalMobileCapacity = () => {
    setModalOpenMobileCapacity(false)
  }

  useEffect(() => {
    getBaseApi()
  }, [])

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFBB55",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop:
              width > 1200 ? 150 : width > 768 && width < 1200 ? 150 : 94,
            paddingBottom: width > 768 ? 82 : 24,

            maxWidth: 1000,
          }}
        >
          <Row gutter={[0, 24]}>
            <Col xs={24} md={12} xl={12}>
              {banner?.length > 0 ?
                <CorousBanner data={banner} isNavigation={false} />
                :
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '45vh',
                  width: '100%',
                }}>
                  <Spin tip="Loading..." size="large" />
                </div>
              }
            </Col>

            <Col xs={24} md={12} xl={12}>
              <div
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 16,
                    paddingRight: 24,
                    paddingLeft: 24,
                    paddingTop: 18,
                    paddingBottom: 18,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                      <Row gutter={[24, 4]}>
                        <Col span={24}>
                          <div style={{ paddingBottom: 8 }}>
                            <h3 className="ks-item-title">
                              ประเมินราคาโทรศัพท์ที่ต้องการขาย
                            </h3>
                          </div>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="brand"
                            rules={[
                              {
                                required: (!isMobileDevice ? true : (selectedMobileBrand ? false : true)),
                                message: "กรุณาเลือกยี่ห้อโทรศัพท์",
                              },
                            ]}
                          >
                            {!isMobileDevice ?
                              <Select
                                showSearch
                                placeholder="เลือกยี่ห้อโทรศัพท์"
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                allowClear
                                size="large"
                                onChange={async (e) => {
                                  await getMobileModel(e);
                                }}
                                onFocus={(e) => {
                                  e.target.readOnly = true;
                                  e.preventDefault();
                                }}
                                onBlur={(e) => {
                                  e.target.readOnly = false;
                                }}
                              >
                                {mobileBrand?.map((item, index) => {
                                  return (
                                    <Option key={index} value={item?.id}>
                                      {item?.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                              :
                              <div>
                                <div className="select-mobile" onClick={openModalMobileBrand}>
                                  <div>
                                    {selectedMobileBrand ?
                                      <span style={{ fontSize: 18, color: "black" }}>{selectedMobileBrand.title}</span>
                                      :
                                      <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกยี่ห้อโทรศัพท์</span>
                                    }
                                  </div>
                                  <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                                </div>

                                {isModalOpenMobileBrand && (
                                  <div className="modal">
                                    <div className="modal-content">
                                      <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                        <span className="close" onClick={closeModalMobileBrand}>&times;</span>
                                        <span>ยี่ห้อโทรศัพท์</span>
                                      </div>
                                      <div className="options">
                                        {mobileBrand?.map((item, index) => (
                                          <div
                                            className="option"
                                            key={index}
                                            onClick={async () => {
                                              await getMobileModel(item.id);
                                              setSelectedMobileBrand(item)
                                              closeModalMobileBrand()
                                            }}
                                          >
                                            {item?.title}
                                          </div>
                                        ))}
                                        {mobileBrand?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            }
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="model"
                            rules={[
                              {
                                required: (!isMobileDevice ? true : (selectedMobileModel ? false : true)),
                                message: "กรุณาเลือกรุ่นโทรศัพท์",
                              },
                            ]}
                          >
                            {!isMobileDevice ?
                              <Select
                                showSearch
                                placeholder="เลือกรุ่นโทรศัพท์"
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                allowClear
                                size="large"
                                onChange={async (e) => {
                                  await getMobileCapacity(e);
                                }}
                                onFocus={(e) => {
                                  e.target.readOnly = true;
                                  e.preventDefault();
                                }}
                                onBlur={(e) => {
                                  e.target.readOnly = false;
                                }}
                              >
                                {mobileModel?.map((item, index) => {
                                  return (
                                    <Option key={index} value={item?.id}>
                                      {item?.title}
                                    </Option>
                                  );
                                })}
                              </Select>
                              :
                              <div>
                                <div className="select-mobile" onClick={openModalMobileModel}>
                                  <div>
                                    {selectedMobileModel ?
                                      <span style={{ fontSize: 18, color: "black" }}>{selectedMobileModel.title}</span>
                                      :
                                      <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกรุ่นโทรศัพท์</span>
                                    }
                                  </div>
                                  <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                                </div>

                                {isModalOpenMobileModel && (
                                  <div className="modal">

                                    <div className="modal-content">
                                      <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                        <span className="close" onClick={closeModalMobileModel}>&times;</span>
                                        <span>รุ่นโทรศัพท์</span>
                                      </div>
                                      <div className="options">
                                        {mobileModel?.map((item, index) => (
                                          <div
                                            className="option"
                                            key={index}
                                            onClick={async () => {
                                              await getMobileCapacity(item.id)
                                              setSelectedMobileModel(item)
                                              closeModalMobileModel()
                                            }}
                                          >
                                            {item?.title}
                                          </div>
                                        ))}
                                        {mobileModel?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            }
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="capacity"
                            rules={[
                              {
                                required: (!isMobileDevice ? true : (selectedMobileCapacity ? false : true)),
                                message: "กรุณาเลือกความจุ GB",
                              },
                            ]}
                          >
                            {!isMobileDevice ?
                              <Select
                                showSearch
                                placeholder="เลือกความจุ GB"
                                style={{ width: "100%" }}
                                optionFilterProp="children"
                                allowClear
                                size="large"
                                onFocus={(e) => {
                                  e.target.readOnly = true
                                  e.preventDefault()
                                }}
                                onBlur={(e) => {
                                  e.target.readOnly = false
                                }}
                              >
                                {mobileCapacity?.map((item, index) => {
                                  return (
                                    <Option key={index} value={item?.id}>
                                      {item?.capacity}
                                    </Option>
                                  )
                                })}
                              </Select>
                              :
                              <div>
                                <div className="select-mobile" onClick={openModalMobileCapacity}>
                                  <div>
                                    {selectedMobileCapacity ?
                                      <span style={{ fontSize: 18, color: "black" }}>{selectedMobileCapacity.capacity}</span>
                                      :
                                      <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกความจุ GB</span>
                                    }
                                  </div>
                                  <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                                </div>

                                {isModalOpenMobileCapacity && (
                                  <div className="modal">
                                    <div className="modal-content">
                                      <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                        <span className="close" onClick={closeModalMobileCapacity}>&times;</span>
                                        <span >ความจุโทรศัพท์</span>
                                      </div>
                                      <div className="options">
                                        {mobileCapacity?.map((item, index) => (
                                          <div
                                            className="option"
                                            key={index}
                                            onClick={async () => {
                                              await getMobileCapacity(item.id)
                                              setSelectedMobileCapacity(item)
                                              closeModalMobileCapacity()
                                            }}
                                          >
                                            {item?.capacity}
                                          </div>
                                        ))}
                                        {mobileCapacity?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                      </div>

                                    </div>
                                  </div>
                                )}
                              </div>
                            }
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Button
                            type="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.scroll(0, 0)
                              form.submit()
                            }}
                          >
                            <span
                              // className="ks-item-title"
                              style={{ cursor: "pointer", fontSize: 18 }}
                            >
                              เริ่มที่นี่
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#FF9900",
          paddingTop: 28,
          paddingBottom: 28,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span className="ks-item-title" style={{ color: "white" }}>
          มาขายที่หน้าร้านรับเพิ่ม{" "}
          <soan style={{ color: "#FF0000", fontWeight: 600 }}>500</soan> บาท
        </span>
      </div>

      <style>{`
        .modal {
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgba(0, 0, 0, 0.0);
        }
        .modal-content {
          position: absolute;
          top: 30%; /* ปรับให้สูงขึ้น */
          left: 50%;
          transform: translate(-50%, -30%); /* ปรับตามตำแหน่งที่สูงขึ้น */
          background-color: rgba(66, 66, 69);
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
          color: white;
          // padding: 20px;
          // border: 1px solid #888;
          border-radius: 8px;
          width: 300px;
          max-width: 500px;
          max-height: 90%; /* กำหนดความสูงสูงสุด */
          overflow-y: auto; /* ให้มี scroll เมื่อข้อมูลเกิน */
        }
        .close {
          color: white;
          float: right;
          font-size: 28px;
          font-weight: bold;
          margin-top: -11px;
        }
        .close:hover,
        .close:focus {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
        .options {
          margin-top: 20px;
        }
        .option {
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;
          border: 1px solid white; 
          border-width: 1px 0px 0px 0px;
        }
        .option:hover {
          background-color: rgba(255, 255, 255, 0.2); /* สีพื้นหลังเมื่อ hover */
        }
        .select-mobile {
          border: 1.5px solid #BBC4C2;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 6px;
          padding-bottom: 6px;
          border-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
      `}</style>

    </>
  );
}
