import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function MainLayout({ children }) {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "Kingdom Store",
                            "url": "https://kingdomstore.org",
                            "logo": "https://kingdomstore.org/assets/images/logo/logo-kingdom-store.jpg",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+66909243388",
                                "contactType": "Customer Service",
                                "areaServed": "TH",
                                "availableLanguage": "Thai"
                            },
                            "sameAs": [
                                "https://page.line.me/kingdomstore?openQrModal=true"
                            ]
                        }
                    `}
                </script>

                {/* Google Analytics - Of User */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-864472463"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-864472463');
                    `}
                </script>

                {/* Google Tag Manager - Of SPM */}
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-T2MSSJ6L');
                    `}
                </script>

                {/* Facebook Pixel */}
                <script async defer crossorigin="anonymous" src="https://connect.facebook.net/th_TH/sdk.js#xfbml=1&version=v19.0&appId=595238731515893" nonce="r8nVMl53"></script>

            </Helmet>

            <main>{children}</main>
        </>
    );
}

export default MainLayout;
