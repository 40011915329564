import { useEffect, useState } from "react";

// ฟังก์ชันหลัก (ไม่เปลี่ยนแปลง)
export default async function ScreenManager() {
    return {};
}

// Hook สำหรับจัดการขนาดหน้าจอ
export const useDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        // ตรวจสอบว่า window มีอยู่หรือไม่ (ทำงานในฝั่งไคลเอนต์เท่านั้น)
        if (typeof window !== "undefined") {
            const handleResize = () => setWindowDimensions(getWindowDimensions());
            // ตั้งค่าเริ่มต้นเมื่อโหลดครั้งแรก
            setWindowDimensions(getWindowDimensions());
            // เพิ่ม event listener สำหรับ resize
            window.addEventListener("resize", handleResize);
            // ล้าง event listener เมื่อ component unmount
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return windowDimensions;
};

// ฟังก์ชันช่วยเหลือสำหรับดึงขนาดหน้าจอ
const getWindowDimensions = () => {
    // ตรวจสอบว่า window มีอยู่หรือไม่
    if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    // คืนค่าเริ่มต้นถ้าไม่มี window (ฝั่งเซิร์ฟเวอร์)
    return { width: 0, height: 0 };
};