
import React, { useEffect, useState } from 'react'
import Carouse from '../../../../common/components/carouse'
import { Col, Row } from 'antd'
import { getReviewsFetch } from '../../API'

export default function Review() {

    const [loading, setLoading] = useState(false)

    const [reviews, setReviews] = useState([])

    const getBaseApi = async () => {
        setLoading(true)

        const result = await getReviewsFetch(null, null, null)
        // console.log("getReviewsFetch : ", result)
        if (result) {
            setReviews(result)
        }

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
        // genScroll()
    }, [])

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                backgroundColor: "#EEEEEE"
                // paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                // paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24)
            }}
        >

            <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>รีวิวจากลูกค้า</label>
                </Col>

                <Col span={24}>
                    <div
                        style={{
                            paddingTop: 24,
                            paddingBottom: 0
                        }}
                    >
                        <Carouse data={reviews} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}
