// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide img {
    display: block;
    object-fit: cover;
}

.swiper-slide {
    width: auto;
}

.swiper-button-prev {
    background-color: rgb(255, 153, 0, 0.6);
    color: white;
    border-radius: 10px 10px 10px 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -27px;
}

.swiper-button-next {
    background-color: rgb(255, 153, 0, 0.6);
    color: white;
    border-radius: 10px 10px 10px 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -27px;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: white;
}

.swiper-pagination-bullet-active {
    background-color: white;
}

.ant-select-selector {
    /* border: 2px solid #C1C1C1 !important */
}`, "",{"version":3,"sources":["webpack://./src/client/modules/estimatePrice/components/main/css/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uCAAuC;IACvC,YAAY;IACZ,kCAAkC;IAClC,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,uCAAuC;IACvC,YAAY;IACZ,kCAAkC;IAClC,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".swiper-slide img {\n    display: block;\n    object-fit: cover;\n}\n\n.swiper-slide {\n    width: auto;\n}\n\n.swiper-button-prev {\n    background-color: rgb(255, 153, 0, 0.6);\n    color: white;\n    border-radius: 10px 10px 10px 10px;\n    padding-top: 5px;\n    padding-bottom: 5px;\n    padding-left: 5px;\n    padding-right: 5px;\n    margin-top: -27px;\n}\n\n.swiper-button-next {\n    background-color: rgb(255, 153, 0, 0.6);\n    color: white;\n    border-radius: 10px 10px 10px 10px;\n    padding-top: 5px;\n    padding-bottom: 5px;\n    padding-left: 5px;\n    padding-right: 5px;\n    margin-top: -27px;\n}\n\n.swiper-pagination-bullet {\n    width: 15px;\n    height: 15px;\n    background-color: white;\n}\n\n.swiper-pagination-bullet-active {\n    background-color: white;\n}\n\n.ant-select-selector {\n    /* border: 2px solid #C1C1C1 !important */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
