// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-evaluation-result {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
}

.card-evaluation-result:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.card-purchase-item {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 24px;
}

.card-purchase-item:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.purchasing-location {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 24px;
    display: grid;
}

.purchasing-location:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/client/modules/summaryResult/css/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".card-evaluation-result {\n    border-radius: 8px;\n    border: 1px solid #EEEEEE;\n}\n\n.card-evaluation-result:hover {\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n}\n\n.card-purchase-item {\n    border-radius: 8px;\n    border: 1px solid #EEEEEE;\n    padding: 24px;\n}\n\n.card-purchase-item:hover {\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n}\n\n.purchasing-location {\n    border-radius: 8px;\n    border: 1px solid #EEEEEE;\n    padding: 24px;\n    display: grid;\n}\n\n.purchasing-location:hover {\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
