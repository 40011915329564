/* eslint-disable react-hooks/exhaustive-deps */
// 
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { Col, Row, Spin } from 'antd'
import { getSaleStepContentFetch } from '../../API/estimatePriceApi'

export default function EasyToSell() {

    const { width } = useDimensions()

    const [saleStepContent, setSaleStepContent] = useState([])

    const getSaleStepContent = async (id) => {
        let param = {}
        const result = await getSaleStepContentFetch(param, null, null)
        // console.log("getSaleStepContentFetch : ", result?.result)
        if (result?.status === 200) {
            setSaleStepContent(result?.result)
        }
    }

    const getBaseApi = async () => {
        await getSaleStepContent()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{
                display: "grid",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    paddingTop: 82,
                    paddingBottom: 82,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1000
                }}
            >
                <div style={{ textAlign: "center", paddingBottom: 30 }}>
                    <h2 className='ks-title'>ขายง่ายๆขั้นตอนไม่เยอะ</h2>
                </div>

                <Row gutter={[24, 24]}>
                    {saleStepContent?.map((item, index) => {
                        return (
                            <Col key={index} xs={24} sm={12} md={8} lg={8}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: 30 }}>
                                        <div className='icon-bg-sale-step' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img
                                                src={item?.SaleStepContentCover?.googleImage}
                                                className='icon-sale-step'
                                                alt={item?.title}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ paddingBottom: 15 }}>
                                        <h3 className='ks-item-title'>{item?.title}</h3>
                                    </div>

                                    <div>
                                        <span className='ks-item-detail'>{item?.description}</span>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}

                    {saleStepContent?.length === 0 ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20vh',
                            width: '100%',
                        }}>
                            <Spin tip="Loading..." size="large" />
                        </div>
                        : []
                    }
                </Row>
            </div>
        </div>
    )
}