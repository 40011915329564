// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-detail-board {
    background-color: black;
    color: white;
    border-radius: 0%;
}

.complaint-board {
    width: 200px;
}

.complaint-item {
    padding: 5px;
}

.complaint-item:hover {
    background-color: #429321;
    padding: 5px;
    margin: 0px;
    cursor: pointer;
}

.btn-consult {
    display: flex;
    align-items: center; 
    justify-content: center; 
    background-color: #429321; 
    padding: 8px;
    border-radius: 50px; 
    width: 150px;
    color: black;
}

.btn-consult:hover {
    background-color: #429321;
    cursor: pointer;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/client/common/components/footer/css/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".footer-detail-board {\n    background-color: black;\n    color: white;\n    border-radius: 0%;\n}\n\n.complaint-board {\n    width: 200px;\n}\n\n.complaint-item {\n    padding: 5px;\n}\n\n.complaint-item:hover {\n    background-color: #429321;\n    padding: 5px;\n    margin: 0px;\n    cursor: pointer;\n}\n\n.btn-consult {\n    display: flex;\n    align-items: center; \n    justify-content: center; \n    background-color: #429321; \n    padding: 8px;\n    border-radius: 50px; \n    width: 150px;\n    color: black;\n}\n\n.btn-consult:hover {\n    background-color: #429321;\n    cursor: pointer;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
