const initialState = {
    data: 'Initial data from server'
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.payload };
        default:
            return state;
    }
};

module.exports = rootReducer; // ใช้ CommonJS