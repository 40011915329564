/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function HighlightService() {

    const { width } = useDimensions()

    return (
        <div>
            <div
                style={{
                    borderRadius: 8,
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Row
                    gutter={[(width > 767 ? 24 : 0), 24]}
                    style={{
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1000,
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <label style={{ fontSize: 26 }}>ขายกับเราดียังไง</label>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img
                                    src={"./assets/images/icon/checklist.png"}
                                    style={{ height: 60, padding: 24 }}
                                    alt="มีมาตรฐานในการตรวจสินค้า ไม่กดราคา เราประเมินราคาให้สูงแน่นอน"
                                />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    มีมาตรฐานในการตรวจสินค้า ไม่กดราคา เราประเมินราคาให้สูงแน่นอน
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img
                                    src={"./assets/images/icon/sale.png"}
                                    style={{ height: 60, padding: 24 }}
                                    alt="สามารถต่อรองพูดคุยราคากับทางเราได้ เพื่อที่จะให้ลูกค้าได้ตัดสินใจ"
                                />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    สามารถต่อรองพูดคุยราคากับทางเราได้ เพื่อที่จะให้ลูกค้าได้ตัดสินใจ
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img
                                    src={"./assets/images/icon/dust.png"}
                                    style={{ height: 60, padding: 24 }}
                                    alt="ข้อมูลไม่รั่วไหล เราจะล้างข้อมูลทันที หลังจากลูกค้าตกลงขายกับทางเรา"
                                />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    ข้อมูลไม่รั่วไหล เราจะล้างข้อมูลทันที หลังจากลูกค้าตกลงขายกับทางเรา
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img
                                    src={"./assets/images/icon/deadline.png"}
                                    style={{ height: 60, padding: 24 }}
                                    alt="ใช้เวลาไม่นาน อยู่ที่ไหนก็ขายได้ แม้อยู่ต่างจังหวัดก็สามารถขายได้"
                                />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    ใช้เวลาไม่นาน อยู่ที่ไหนก็ขายได้ แม้อยู่ต่างจังหวัดก็สามารถขายได้
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
