/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useDimensions } from "../../../../common/mamager/ScreenManager"

export default function BrandHilight() {
    const { width } = useDimensions()
    return (
        <>
            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "grid",
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1000,
                    }}
                >
                    <label className="ks-item-title" style={{ paddingBottom: 30, color: "#FF9900" }}>
                        <h3 style={{ fontWeight: 600 }}>Kingdom Store</h3>
                        <h3>อยากขายโทรศัพท์ให้คิดถึงเรา รับซื้อโทรศัพท์มือสอง iPhone iPhone iPad MacBook ให้ราคาสูง บริการรับถึงที่</h3>
                    </label>

                    <label className="ks-item-title" style={{ paddingBottom: 30 }}>
                        <h3>เราให้บริการรับซื้อโทรศัพท์มือถือมือสอง iPhone iPad MacBook รับซื้อโทรศัพท์ทุกยี่ห้อ ทุกรุ่น ไม่ว่าจะเป็นเครื่องเก่าหรือใหม่ เรายังรับซื้อเครื่องใหม่ไม่แกะเครื่อง ไม่แอคติเวทเครื่อง รับซื้อเครื่องบริษัทจำนวนมากๆ พร้อมจะประเมินราคาให้สูงกว่าท้องตลาด สามารถทำรายการขายสินค้าของลูกค้าและทราบราคาได้ทันที</h3>
                    </label>

                    <label className="ks-item-detail">
                        <h4>โดยเราจะมีพนักงานเข้ารับซื้อโทรศัพท์ถึงหน้าบ้าน คอนโด ที่ทำงาน ตามสถานที่ที่ลูกค้าสะดวก เมื่อตกลงขาย เราจ่ายเงินสดหรือโอนทันทีโดยที่ลูกค้าไม่ต้องเสียเวลาเดินทางมาที่ร้าน สะดวก รวดเร็ว ปลอดภัย ข้อมูลไม่รั่วไหล Kingdom store รับซื้อหลากหลาย ยี่ห้อไม่ว่าจะเป็น Apple (แอปเปิล) iPhone (ไอโฟน) iPad (ไอแพด) MacBook (แมคบุ๊ค) Samsung (ซัมซุง) Xiaomi (เสียวหมี่) Oppo (ออปโป้) Vivo (วีโว้) Huawei (หัวเว่ย) Redmi (เรดหมี่) Realme (เรียวมี) Honor (ออเนอร์)</h4>
                    </label>
                </div>
            </div>
        </>
    )
}