/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { Col, Row, Spin } from 'antd'
import { getSaleByKingdomFetch } from '../../API/estimatePriceApi'

export default function SoldAtKingdomStore() {

    const { width } = useDimensions()

    const [saleByKingdom, setSaleByKingdom] = useState([])

    const getSaleByKingdom = async (id) => {
        let param = {}
        const result = await getSaleByKingdomFetch(param, null, null)
        // consoles.log("getSaleByKingdomFetch : ", result?.result)
        if (result?.status === 200) {
            setSaleByKingdom(result?.result)
        }
    }

    const getBaseApi = async () => {
        await getSaleByKingdom()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{
                backgroundColor: "#FFBB55",
                display: "grid",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    paddingTop: 82,
                    paddingBottom: 82,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1000
                }}
            >
                <div style={{ textAlign: "center", paddingBottom: 30 }}>
                    <h2 className='ks-title'>ขายที่ Kingdom Store ดีอย่างไร</h2>
                </div>

                <Row gutter={[24, 24]}>
                    {saleByKingdom?.map((item, index) => {
                        return (
                            <Col key={index} xs={24} sm={12} md={6} lg={6}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: 30 }}>
                                        <div style={{ backgroundColor: "white", borderRadius: 120, width: 120, height: 120, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                            <img
                                                src={item?.SaleByKingdomCover?.googleImage}
                                                style={{ width: 84, height: 84 }}
                                                alt={item?.title}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ paddingBottom: 15 }}>
                                        <h3 className='ks-item-title'>{item?.title}</h3>
                                    </div>

                                    <div>
                                        <span className='ks-item-detail'>{item?.description}</span>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}

                    {saleByKingdom?.length === 0 ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20vh',
                            width: '100%',
                        }}>
                            <Spin tip="Loading..." size="large" />
                        </div>
                        : []
                    }
                </Row>
            </div>
        </div>
    )
}