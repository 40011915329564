/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import "./css/index.css"
import React from "react";
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { Carousel } from "antd"

export default function CorousBanner(props) {

    const { width } = useDimensions()

    const onChange = (currentSlide) => {}

    return (
        <div>
            {props.data?.length > 0 ?
                <center>
                    <Carousel
                        autoplay
                        autoplaySpeed={5000}
                        afterChange={onChange}
                        style={{ width: width >= 1280 ? "100%" : (width >= 768 ? 400 : 300) }}
                    >
                        {props.data.map((val, index) =>

                            <img
                                src={`${val?.BannerImage?.googleImage}`}
                                alt="banner"
                                style={{
                                    width: "100%", // width >= 768 ? "100%" : "auto",
                                    height: 10
                                }}
                            />

                        )}
                    </Carousel>
                </center>
                : []
            }
        </div>
    )
}