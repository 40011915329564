import Axios from 'axios';
import { NOT_CONNECT_NETWORK, NETWORK_CONNECTION_MESSAGE, baseApi } from './../constants';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

let urlJoin; // ตัวแปรสำหรับเก็บ url-join หลังจากโหลด

// ฟังก์ชันเพื่อโหลด url-join แบบ dynamic
async function loadUrlJoin() {
    if (!urlJoin) {
        const module = await import('url-join');
        urlJoin = module.default; // ESM มักจะ export default
    }
    return urlJoin;
}

Axios.interceptors.request.use(async (config) => {
    await loadUrlJoin(); // รอให้ url-join โหลดเสร็จ
    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = urlJoin(baseApi, config.url);
    }
    config.timeout = 10000; // 10 วินาที
    return config;
});

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (Axios.isCancel(error)) {
            return Promise.reject(error);
        } else if (!error.response) {
            return Promise.reject({
                code: NOT_CONNECT_NETWORK,
                message: NETWORK_CONNECTION_MESSAGE,
            });
        }
        return Promise.reject(error);
    }
);

export const httpClient = Axios;