// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.nav-item {
  padding: 0px;
}

.navbar-collapse {
  padding: 0px;
}

.dropdown-toggle {
  padding: 0px;
}

.nav-reminder {
  padding-left: 10px;
  margin-top: -5px;
}

.ant-drawer-header {
  border-radius: 0;
}

div .ant-drawer-body {
  color: #fff;
  height: calc(100vh - 55px);
  padding: 0px;
}

.divStyle {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
}

.divStyle:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/client/common/components/header/css/index.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,6BAA6B;EAC7B,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["#navbar {\n  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);\n}\n\n.nav-item {\n  padding: 0px;\n}\n\n.navbar-collapse {\n  padding: 0px;\n}\n\n.dropdown-toggle {\n  padding: 0px;\n}\n\n.nav-reminder {\n  padding-left: 10px;\n  margin-top: -5px;\n}\n\n.ant-drawer-header {\n  border-radius: 0;\n}\n\ndiv .ant-drawer-body {\n  color: #fff;\n  height: calc(100vh - 55px);\n  padding: 0px;\n}\n\n.divStyle {\n  height: 70px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  background-color: transparent;\n  transition: background-color 0.3s;\n}\n\n.divStyle:hover {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
