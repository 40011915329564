import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getReviewsFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_PAGE_REVIEWS_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getReviewsFetch,
    
    // insert

    // update

    // delete

}