import React from "react";
import EstimatePrice from '../estimatePrice'
import { Helmet } from "react-helmet-async";

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Kingdom Store | ประเมินราคามือถือ ขายง่าย ได้ราคาสูง</title>
                <meta name="description" content="บริการขายมือถือ ประเมินราคาฟรี ขายสะดวก รับเงินทันที ที่ Kingdom Store" />
                <meta property="og:title" content="Kingdom Store | ประเมินราคามือถือ ขายง่าย ได้ราคาสูง" />
                <meta property="og:description" content="บริการขายมือถือ ประเมินราคาฟรี ขายสะดวก รับเงินทันที ที่ Kingdom Store" />
                <meta property="og:url" content="https://kingdomstore.org/" />
                <meta property="og:image" content="https://kingdomstore.org/assets/images/logo/logo-kingdom-store.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Store",
                            "name": "Kingdom Store",
                            "url": "https://kingdomstore.org",
                            "logo": "https://kingdomstore.org/logo.png",
                            "image": "https://kingdomstore.org/og-image.jpg",
                            "description": "บริการขายมือถือ จำนำมือถือ รับซื้อมือถือ ให้ราคาสูงสุด",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Bangkok",
                                "addressCountry": "TH"
                            },
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+66-xxx-xxx-xxx",
                                "contactType": "customer service"
                            }
                        }
                    `}
                </script>
            </Helmet>
            <EstimatePrice />
        </>
    )
}
