/* eslint-disable react-hooks/exhaustive-deps */

import Main from './components/main'
import {
    Row,
    Col
} from 'antd'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import ContactForm from './components/contactForm'
import { Helmet } from "react-helmet-async";

export default function ContactUs() {

    return (
        <>
            <Helmet>
                <title>ติดต่อ Kingdom Store | ขายมือถือ รับจำนำ รับซื้อทั่วประเทศ</title>
                <meta name="description" content="ติดต่อ Kingdom Store รับซื้อโทรศัพท์ถึงบ้าน บริการรวดเร็ว ทันใจ ทีมงานมืออาชีพ" />
                <meta property="og:title" content="ติดต่อ Kingdom Store | ขายมือถือ รับจำนำ รับซื้อทั่วประเทศ" />
                <meta property="og:description" content="ติดต่อ Kingdom Store รับซื้อโทรศัพท์ถึงบ้าน บริการรวดเร็ว ทันใจ ทีมงานมืออาชีพ" />
                <meta property="og:url" content="https://kingdomstore.org/contact-us" />
                <meta property="og:image" content="https://kingdomstore.org/assets/images/logo/logo-kingdom-store.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <ContactForm />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
