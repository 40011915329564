/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import Main from './components/main'
import {
    Row,
    Col
} from 'antd'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import HighlightService from './components/highlightService'
import ContactSales from './components/contactSales'
import { Helmet } from "react-helmet-async"

export default function MobilePawn() {

    const { width } = useDimensions()

    return (
        <>
            <Helmet>
                <title>จำนำมือถือ ราคาดี ปลอดภัย | Kingdom Store</title>
                <meta name="description" content="บริการจำนำมือถือ iPhone iPad Macbook ให้วงเงินสูง ปลอดภัย ไม่เช็คเครดิต รับเงินทันที" />
                <meta property="og:title" content="จำนำมือถือ ราคาดี ปลอดภัย | Kingdom Store" />
                <meta property="og:description" content="บริการจำนำมือถือ iPhone iPad Macbook ให้วงเงินสูง ปลอดภัย ไม่เช็คเครดิต รับเงินทันที" />
                <meta property="og:url" content="https://kingdomstore.org/mobile-pawn" />
                <meta property="og:image" content="https://kingdomstore.org/assets/images/logo/logo-kingdom-store.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <HighlightService />
                    </Col>

                    <Col span={24}>
                        <ContactSales />
                    </Col>

                    <Col
                        span={24}
                        style={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                paddingTop: 84,
                                paddingBottom: 84,
                                paddingLeft: (width > 1200 ? 0 : 24),
                                paddingRight: (width > 1200 ? 0 : 24),
                                maxWidth: 1000
                            }}
                        >
                            <label style={{ fontSize: 22, fontWeight: 600 }}>
                                รับฝาก รับจำนำโทรศัพท์มือถือ ทุกรุ่นทุกยี่ห้อ iPhone iPad Tablet Notebook Macbook อุปกรณ์ไอทีทุกชนิด ให้ราคาสูง
                            </label>
                            <br />

                            <label style={{ fontSize: 22 }}>
                                ใช้เวลาไม่นานรับเงินสดทันที เรามีหน้าร้านชัดเจนสะดวก ปลอดภัย รวดเร็ว บริการเงินด่วน หมุนเงินไม่ทันต้องการ ใช้เงินด่วนรับเงินสดทันที ทางเราไม่เน้นให้สินค้าหลุด ถึงวันครบกำหนดทางเราจะโทรแจ้งลูกค้า
                            </label>
                        </div>
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
