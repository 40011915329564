/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import React, { useEffect, useState } from 'react';

export default function Carousel(props) {
    const [SwiperComponent, setSwiperComponent] = useState(null);
    const [SwiperSlideComponent, setSwiperSlideComponent] = useState(null);
    const [modules, setModules] = useState(null);

    useEffect(() => {
        // Dynamic import สำหรับ Swiper และ modules
        Promise.all([
            import('swiper/react'),
            import('swiper/modules'),
            import('swiper/css'),
            import('swiper/css/navigation'),
            import('swiper/css/pagination'),
            import("./css/index.css"),
        ]).then(([swiperModule, modulesModule]) => {
            setSwiperComponent(() => swiperModule.Swiper);
            setSwiperSlideComponent(() => swiperModule.SwiperSlide);
            setModules({
                Navigation: modulesModule.Navigation,
                Pagination: modulesModule.Pagination,
                A11y: modulesModule.A11y,
                Autoplay: modulesModule.Autoplay
            });
        });
    }, []);

    // ถ้ายังโหลดไม่เสร็จ ให้แสดง Loading
    if (!SwiperComponent || !SwiperSlideComponent || !modules) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {props.data?.length > 0 ?
                <SwiperComponent
                    modules={[
                        modules.Navigation,
                        modules.Pagination,
                        modules.A11y,
                        modules.Autoplay
                    ]}
                    spaceBetween={12}
                    slidesPerView={"auto"}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                >
                    {props.data.map((val, index) =>
                        <SwiperSlideComponent key={index}>
                            <img
                                src={`${val?.image}`}
                                alt={val?.title ?? ""}
                                style={{
                                    width: "100%",
                                    height: 300,
                                    borderRadius: 15,
                                    border: "1px solid #FF9900"
                                }}
                            />
                            <meta itemprop="name" content={val?.title ?? ""} />
                            <meta itemprop="keywords" content={val?.title ?? ""} />
                        </SwiperSlideComponent>
                    )}
                </SwiperComponent>
                : []
            }
        </div>
    );
}