/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import Main from './components/main'
import {
    Row,
    Col
} from 'antd'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import HighlightService from './components/highlightService'
import ContactSales from './components/contactSales'
import Review from './components/review'
import { Helmet } from "react-helmet-async";

export default function MobilePurchase() {

    const { width } = useDimensions()

    return (
        <>
            <Helmet>
                <title>ขายโทรศัพท์มือถือ รับราคาสูง | Kingdom Store</title>
                <meta name="description" content="บริการรับซื้อโทรศัพท์มือถือ iPhone iPad Macbook ประเมินราคาฟรี รับซื้อถึงบ้าน พร้อมจ่ายเงินทันที" />
                <meta property="og:title" content="ขายโทรศัพท์มือถือ รับราคาสูง | Kingdom Store" />
                <meta property="og:description" content="บริการรับซื้อโทรศัพท์มือถือ iPhone iPad Macbook ประเมินราคาฟรี รับซื้อถึงบ้าน พร้อมจ่ายเงินทันที" />
                <meta property="og:url" content="https://kingdomstore.org/mobile-purchase" />
                <meta property="og:image" content="https://kingdomstore.org/assets/images/logo/logo-kingdom-store.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>

            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <HighlightService />
                    </Col>

                    <Col span={24}>
                        <ContactSales />
                    </Col>

                    <Col
                        span={24}
                        style={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                paddingTop: 84,
                                paddingBottom: 84,
                                paddingLeft: (width > 1200 ? 0 : 24),
                                paddingRight: (width > 1200 ? 0 : 24),
                                maxWidth: 1000,
                            }}
                        >
                            <label style={{ fontSize: 22 }}>
                                เราเป็นบริษัทที่รับซื้อโทรศัพท์มือถือ เครื่องใหม่ เครื่องใหม่ไม่ได้ใช้งาน เครื่องแกะแอคติเวท และโทรศัพท์มือสอง เครื่องได้รับรางวัล รับซื้อเป็นล็อต ล๊อตเล็กล๊อตใหญ่ รับซื้อเครื่องบริษัท เรารับหมดไม่จำกัดจำนวนให้ราคาสูง และที่สำคัญเราสามารถพูดคุย ต่อรองราคากับเราได้
                            </label>
                        </div>
                    </Col>

                    <Col span={24}>
                        <Review />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}