import Home from "./modules/home";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom"; // นำเข้า BrowserRouter จาก react-router-dom
import { StaticRouter } from "react-router-dom/server"; // นำเข้า StaticRouter จาก react-router-dom/server
import { Routes, Route } from "react-router-dom";
import themeAntd from "./styles/themeAntd";
import MobilePurchase from "./modules/mobilePurchase";
import MobilePawn from "./modules/mobilePawn";
import ContactUs from "./modules/contactUs";
import FloatButtons from "./common/components/floatButton";
import EstimatePrice from "./modules/estimatePrice";
import SummaryResult from "./modules/summaryResult";
import Results from "./common/components/result";
import EvaluateIntegrity from "./modules/evaluateIntegrity";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import MainLayout from "./common/components/MainLayout";

if (typeof window !== 'undefined') {
    import("./styles/App.css");
    import("./styles/App.less");
}

const App = ({ routerType = "client", location }) => {
    const RouterComponent = routerType === "server" ? StaticRouter : BrowserRouter;
    const routerProps = routerType === "server" ? { location } : {};

    return (
        <HelmetProvider>
            <ConfigProvider theme={{ token: themeAntd }}>
                <RouterComponent {...routerProps}>
                    <MainLayout>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/sell-phone" element={<MobilePurchase />} />
                            <Route exact path="/phone-pawn" element={<MobilePawn />} />
                            <Route exact path="/contact-us" element={<ContactUs />} />
                            <Route exact path="/phone-estimate-price" element={<EstimatePrice />} />
                            <Route exact path="/phone-evaluate-integrity" element={<EvaluateIntegrity />} />
                            <Route exact path="/phone-summary-result" element={<SummaryResult />} />
                            <Route exact path="/result" element={<Results />} />
                            <Route path="/:id">Page not found!</Route>
                        </Routes>
                    </MainLayout>
                </RouterComponent>
                <FloatButtons />
            </ConfigProvider>
        </HelmetProvider>
    );
};

export default App;