/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import {
    Row,
    Col
} from 'antd'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import Main from './components/main'
import EasyToSell from './components/easyToSell'
import SoldAtKingdomStore from './components/soldAtKingdomStore'
import BrandHilight from './components/brandHilight'
import Review from './components/review'
import { Helmet } from "react-helmet-async"

export default function EstimatePrice() {
    return (
        <>
            <Helmet>
                <title>เช็คราคามือถือ ประเมินฟรี รู้ราคาทันที | Kingdom Store</title>
                <meta name="description" content="ประเมินราคามือถือ iPhone iPad Macbook ด้วยตัวเอง รู้ราคาทันที ขายง่าย ได้ราคาดี พร้อมบริการรับซื้อถึงบ้าน" />
                <meta property="og:title" content="เช็คราคามือถือ ประเมินฟรี รู้ราคาทันที | Kingdom Store" />
                <meta property="og:description" content="ประเมินราคามือถือ iPhone iPad Macbook ด้วยตัวเอง รู้ราคาทันที ขายง่าย ได้ราคาดี พร้อมบริการรับซื้อถึงบ้าน" />
                <meta property="og:url" content="https://kingdomstore.org/estimate-price" />
            </Helmet>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <EasyToSell />
                    </Col>

                    <Col span={24}>
                        <SoldAtKingdomStore />
                    </Col>

                    <Col span={24}>
                        <BrandHilight />
                    </Col>

                    <Col span={24}>
                        <Review />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}